td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight {
  font-size: small;
}
.MuiTableCell-root {
  font-size: small !important;
}
p#mui-80410 {
  font-size: small;
}
.MuiTablePagination-root {
  font-size: small;
}
p.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit {
  font-size: small;
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiTablePagination-menuItem.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  font-size: small;
}
.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot {
  font-size: small;
}
.MuiTypography-body1 {
  font-size: initial !important;
}
h6.MuiTypography-root.MuiTypography-h6.MuiTypography-noWrap {
  font-size: initial;
}
body {
  font-size: initial !important;
}
th#enhanced-table-checkbox-0 {
  width: 50px;
  height: 50px;
}
input.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: initial;
}
input.form-control {
  border: 1px solid #c4c4c4;
  outline: #c4c4c4;
  font-size: small;
  height: 52px;
}
.ms_pro_form .form-group label {
  font-size: 16px;
  color: #3f51b5;
  margin-bottom: 5px;
}
span.MuiChip-label {
  font-size: small;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  font-size: small;
}
.MuiAutocomplete-groupLabel {
  font-size: small;
}
div.MuiAutocomplete-popper {
  font-size: initial !important;
}
input#multiple-limit-tags {
  font-size: small;
}
label#multiple-limit-tags-label {
  font-size: small;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #3f51b5;
}
.ForwardRef-root-42.SnackbarItem-contentRoot-33 {
  font-size: small;
}
p.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error {
  font-size: small;
}
p.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error {
  display: block;
}
p.MuiFormHelperText-root.MuiFormHelperText-contained {
  display: none;
}
.MuiFormControl-root.MuiTextField-root.form-control {
  margin-bottom: 20px;
}.SnackbarItem-message-39{
  font-size: small;
}.SnackbarItem-variantSuccess-39 {
  color: #fff;
  background-color: #43a047;
  font-size: medium;
}h2.MuiTypography-root.MuiTypography-h6 {
  font-size: medium;
}

.SnackbarItem-message-42{
  font-size: small;
}.SnackbarItem-variantSuccess-42 {
  color: #fff;
  background-color: #43a047;
  font-size: medium;
}
input.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: small;
}.SnackbarItem-message-59{
  font-size: small;
}.SnackbarItem-message-52{
  font-size: small;
}.MuiSnackbar-root{
  font-size: small;
}.SnackbarItem-contentRoot-51{
  font-size: small !important;
}